import Vue from 'vue'
import Vuex from 'vuex'
import sgjorder from './ordersgj/ordersgj'

Vue.use(Vuex)

export default function(/* { ssrContext } */) {
    const Store = new Vuex.Store({
      modules: {
        sgjorder
      },
  
      // enable strict mode (adds overhead!)
      // for dev mode only
      strict: process.env.DEBUGGING
    });
  
    return Store;
  }
