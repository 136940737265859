<template>
  <v-row justify="center">
    <v-dialog v-model="dialogWarning" persistent max-width="600px">
      <v-card>
        <v-card-title align-center>
          <span class="text-h5">กรุณากรอกฟอร์มให้สมบูรณ์</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="button" @click="close" color="pink darken-4"><h4 style="color:#cdb96e;">CLOSE</h4></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "DialogWarning",
  computed: {
    ...mapState({
      dialogWarning: (state) => state.sgjorder.dialogWarning,
    }),
  },
  methods: {
    close() {
      this.$store.commit("SET_DIALOGW", false);
    },
  },
};
</script>

<style lang="sass" scoped>
.button
    width:100px
    height: 50px
</style>