<template>
  <v-row justify="center">
    <v-dialog v-model="dialogWarning" persistent max-width="600px">
      <v-card>
        <v-card-title align-center>
          <span class="text-h5">กรุณากรอกชื่อร้านค้า</span>
        </v-card-title>
        <v-card-actions>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="nameshop"
              label="ชื่อร้านค้า"
              outlined
              clearable
            ></v-text-field>
            <v-row align="center" justify="space-around">
              <v-btn class="button" @click="save" color="pink darken-4"
                ><h4 style="color:#cdb96e;">SAVE</h4></v-btn
              >
              <v-btn class="button" @click="close" color="pink darken-4"
                ><h4 style="color:#cdb96e;">CLOSE</h4></v-btn
              >
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "DialogCheckName",
  data() {
    return {
      nameshop: "",
    };
  },
  computed: {
    ...mapState({
      dialogWarning: (state) => state.sgjorder.dialogWarning,
      userProfile: (state) => state.sgjorder.userProfile,
    }),
  },
  methods: {
    close() {
      this.$store.commit("SET_DIALOGW", false);
    },
    save() {
      let data = {
        id: this.userProfile.id,
        name: this.nameshop,
        line_uuid: this.userProfile.line_uuid,
      };
      console.log(data);
      this.$store.dispatch("addNameShop", data);
      this.$store.commit("SET_DIALOGW", false);
    },
  },
};
</script>
