<template>
  <div>
    <v-col align="center">
      <h2>:: Catalog ::</h2>
      <v-card class="pt-10 mt-10" height="200" width="300" elevation="8">
        <v-col align="center">
          <v-row justify="center"><v-btn class="button" @click="$router.push('category/family_ring')"
              color="pink darken-4">
              <h4 style="color:#cdb96e;">แหวนนามสกุล</h4>
            </v-btn></v-row>
          <v-row justify="center" class="pt-5"><v-btn class="button" @click="$router.push('category/neck')"
              color="pink darken-4">
              <h4 style="color:#cdb96e;">สร้อยคอ</h4>
            </v-btn></v-row>
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Category",
  ...mapState({
    company: (state) => state.sgjorder.company,
    branch: (state) => state.sgjorder.branch,
    token: (state) => state.sgjorder.token,
  }),
  mounted: function () {
    // console.log(this.branch);
    let company = sessionStorage.getItem('company_id');
    // console.log(company.length);
    if (company != undefined) {
      let qcom = this.$route.query.company;
      let qbranch = this.$route.query.branch;
      let qtoken = this.$route.query.token;
      if (qcom != undefined && qbranch != undefined && qtoken != undefined) {
        sessionStorage.setItem("company_id", JSON.stringify(this.$route.query.company));
        sessionStorage.setItem("branch_no", JSON.stringify(this.$route.query.branch));
        sessionStorage.setItem("token", JSON.stringify(this.$route.query.token));
        this.$store.commit("SET_COMPANY", this.$route.query.company);
        this.$store.commit("SET_BRANCH", this.$route.query.branch);
        this.$store.commit("SET_TOKEN", this.$route.query.token);
      }
      // if (isLocalStorage() /* function to detect if localstorage is supported*/) {
      // localStorage.setItem('company', this.$route.query.company)
      // }


      // console.log(this.$route.query.company);
    }
    else{
      let qcom = this.$route.query.company;
      let qbranch = this.$route.query.branch;
      let qtoken = this.$route.query.token;
      if (qcom != undefined && qbranch != undefined && qtoken != undefined) {
        sessionStorage.setItem("company_id", JSON.stringify(this.$route.query.company));
        sessionStorage.setItem("branch_no", JSON.stringify(this.$route.query.branch));
        sessionStorage.setItem("token", JSON.stringify(this.$route.query.token));
        this.$store.commit("SET_COMPANY", this.$route.query.company);
        this.$store.commit("SET_BRANCH", this.$route.query.branch);
        this.$store.commit("SET_TOKEN", this.$route.query.token);
      }
    }


    // $route.params.id
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.button {
  width: 100px;
  height: 50px;
  font-weight: bold;
}
</style>
