<template>
  <div>
    <v-col align="center">
      <v-card> 
          <v-img :src="require('../assets/catalog-yaowarat1.jpg')">
        </v-img>
      </v-card>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "Neck",
};
</script>
