/* eslint-disable no-unused-vars */
import axios from "axios";

const api = axios.create({
    baseURL: "https://mygold.vip/api"
  });


const state = {
    product:[],
    showbtn:false,
    orderDetail:{
        type:"",
        code:"",
        name:"",
        text:"",
        note:"",
        variants:"",
        variant_weight:"",
        variant_font: "",
        variant_background :"",
        img:"",
        size:"",
        product_id:"",
        colorBG:"",
        colorFT:"",
        font:""
    },
    dialog:false,
    dialogWarning:false,
    user: {
        display_name: "",
        line_uuid: "",
        picture_url: ""
      },
    userProfile:{},
    order:[],
    branch_code:""

};

const getters = {};

const actions = {
    getProduct: async ({ commit }, category_mode) => {
        const response = await api.get("/get_product_by_category/"+category_mode,{
        })
        .then(res => {
            commit("SET_PRODUCT",res.data)
            console.log("state",state.product);
            return res.data
        })
        .catch(error => {
            return error;
        })
        return response;
    },
    createOrder: async ({commit},data) => {
        const response = await api.post("/order_create",{
            data
        })
        .then(res => {
            console.log(res.data);
            return res.data
        })
        .catch(error => {
            return error;
        })
    },
    addNameShop: async ({commit}, data) => {
        console.log(data);
        const response = await api.post("/add_shop", {
            data
        })
        .then(res => {
            console.log(res.data);
        })
        .catch(error => {
            return error
        })
        return response
    },
    addPic: async ({commit}, data) => {
        console.log(data);
        const response = await api.post("/add_pic", {
            data
        })
        .then(res => {
            console.log(res.data);
        })
        .catch(error => {
            return error
        })
        return response
    },
    createLineUser: async ({commit}, user) =>{
        await api.post("/create_line_user",{
            user
        })
        .then(res => {
            return res.data
        })
        .catch(error => {
            return '404'
        })
    },
    getOrder: async ({commit}, data) =>{
        //,company, branch, token
        const response = await api.get("/get_order?company_id="+data.company_id+"&branch_no="+data.branch_no+"&token="+data.token,{
            headers:{}
        })
        .then(res =>{
            commit("SET_ORDER",res.data)
            return res.data
        })
        .catch(error => {
            return '404'
        })
        return response
    },
    getUser: async ({commit},line_uuid) =>{
        const response = await api.get("/get_user_profile/"+line_uuid,{
            headers:{}
        })
        .then(res => {
            commit("SET_USERPROFILE",res.data)
            console.log('getUserProfile',res.data);
            return res.data
        })
        .catch(error => {
            return error
        })
        return response
    },
    cancelOrder: async ({commit},data) => {
        await api.post("/cancel_order",{
            data
        })
        .then(res => {
            console.log(res.data);
            return res.data
        })
        .catch(error => {
            return error;
        })
    },
};

const mutations = {

    SET_PRODUCT(state, payload){
        state.product = payload;
    },
    SET_DETAIL(state, payload){
        state.orderDetail = payload;
    },
    SET_DIALOG(state, payload){
        state.dialog = payload;
    },
    SET_USER(state, payload) {
        state.user = payload;
    },
    SET_ORDER(state, payload) {
        state.order = payload
    },
    SET_DIALOGW(state, payload){
        state.dialogWarning = payload
    },
    SET_USERPROFILE(state,payload){
        state.userProfile = payload
    },
    SET_SHOWBTN(state,payload){
        state.showbtn = payload
    },
    SET_BRANCH(state,payload){
        state.branch = payload
    }
};


export default {
    state,
    getters,
    actions,
    mutations
}
