<template>
  <div>
    <v-col align="center">
      <v-card>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <h3 class="text-center">
                น้ำหนักทอง
              </h3>
            </v-col>
            <v-btn-toggle v-model="toggle_exclusive">
              <v-btn @click="showProduct((value = '1s'))" class="button">
                1 สลึง
              </v-btn>
              <v-btn @click="showProduct((value = '2s'))" class="button">
                2 สลึง
              </v-btn>
              <v-btn @click="showProduct((value = '1b'))" class="button">
                1 บาท
              </v-btn>
            </v-btn-toggle>
          </v-row>
        </v-card-text>

        <v-sheet elevation="8" height="200" width="300">
          <v-card width="300" height="200">
            <div v-if="show1">
              <v-carousel hide-delimiters @change="showDetail($event), reset()" height="200">
                <v-carousel-item v-for="(item, i) in this.product" :key="i" :value="item"
                  :src="`data:image/jpg; base64,` + img" max-width="200" max-height="200"></v-carousel-item>
              </v-carousel>
            </div>
            <div v-if="show2">
              <v-col>
                <h3>กรุณาเลือกน้ำหนักทอง</h3>
              </v-col>
            </div>
          </v-card>
        </v-sheet>
        <h3 class="pt-5">รายละเอียด</h3>
        <h5>{{ detail }}</h5>
        <v-sheet height="50" width="300">
          <v-select @change="selectByCode(), reset()" :items="this.codeRing" item-text="code" :item-value="items"
            label="รหัสแหวน" outlined :rules="[rules.required]" return-object v-model="codeR"></v-select>
        </v-sheet>
        <v-container class="py-3">
          <v-sheet height="50" width="300">
            <v-select :items="ring == '1s'
              ? this.code == 'R004' || this.code == 'R019'
                ? sizeRing1
                : sizeRing
              : sizeRing1
              " label="ขนาดแหวน" outlined :rules="[rules.required]" v-model="size"></v-select>
          </v-sheet>
        </v-container>
        <v-container class="py-3">
          <v-text-field v-model="surname" class="textF"
            :rules="this.code == 'R001' || this.code == 'R002' || this.code == 'R007' || this.code == 'R015' ? nameRules10 : this.code == 'R003' || this.code == 'R008' || this.code == 'R013' ? nameRules8 : nameRules"
            label="ข้อความบนแหวน" @change="checkChar($event)" outlined></v-text-field>
        </v-container>
        <v-container class="py-3">
          <v-sheet height="50" width="300">
            <v-select :items="fonts" label="รูปแบบตัวอักษร" item-text="name" item-value="id" :rules="[rules.required]"
              @change="eventFont($event)" v-model="font" outlined>
              <template v-slot:item="{ item }">
                {{ getText(item) }}
                <h3 :style="{ fontFamily: item.font }">{{ surname }}</h3>
              </template>
            </v-select>
          </v-sheet>
        </v-container>
        <v-container class="py-3">
          <v-sheet width="300" height="50" elevation="8" :color="colorBG">
            <!-- <div v-if="this.font == 'T02'">
              <v-img width="250" height="45" :src="require('../assets/TS-Phikul.png')" />
            </div> -->
            <!-- <div v-else-if="this.font == 'T08'">
              <v-img
                width="250"
                height="45"
                :src="require('../assets/pierre.png')"
                class="pt-2"
              />
            </div > -->
            <!-- <div v-else>
              <h2 class="pt-2" :style="{ fontFamily: changeFont(), color: colorFT }">
                {{ surname == "" ? "รูปแบบข้อความ" : surname }}
              </h2>
            </div> -->
            <div>
              <h2 class="pt-2" :style="{ fontFamily: changeFont(), color: colorFT }">
                {{ surname == "" ? "รูปแบบข้อความ" : surname }}
              </h2>
            </div>
          </v-sheet>
        </v-container>
        <div v-if="this.variant_BGC[0] != 0">
          <v-container class="py-3">
            <v-sheet height="50" width="300">
              <v-select :items="variant_BGC[0]" label=" สีพื้นหลัง" item-text="name" :item-value="items"
                @change="eventBGC()" :rules="[rules.required]" return-object outlined v-model="bgc"></v-select>
            </v-sheet>
          </v-container>
        </div>
        <div v-if="this.variant_FTC[0] != 0">
          <v-container class="py-3">
            <v-sheet height="50" width="300">
              <v-select :items="variant_FTC[0]" label="สีตัวอักษร" item-text="name" :item-value="items"
                @change="eventFTC()" :rules="[rules.required]" return-object outlined v-model="ftc"></v-select>
            </v-sheet>
          </v-container>
        </div>
        <v-container class="py-3">
          <v-text-field class="textF" v-model="note" label="หมายเหตุ" outlined></v-text-field>
        </v-container>
        <v-row class="pt-10 pb-10" align="center" justify="space-around">
          <v-btn class="button" @click="clear" color="pink darken-4">
            <h4 style="color:#cdb96e;">CLEAR</h4>
          </v-btn>
          <v-btn class="button" @click="saveOrder" color="pink darken-4">
            <h4 style="color:#cdb96e;">SAVE</h4>
          </v-btn>
        </v-row>
      </v-card>
      <Dialog />
      <DialogWarning />
    </v-col>
  </div>
</template>

<script>
import Dialog from "../components/dialog.vue";
import DialogWarning from "../components/dialogWarning.vue";
// import liff from "@line/liff";
import { mapState } from "vuex";
export default {
  name: "OrderFamilyRing",
  components: { Dialog, DialogWarning },
  data: () => ({
    toggle_exclusive: false,
    products: [],
    product: [],
    variant_BGC: [],
    variant_FTC: [],
    product_id: null,
    detail: "",
    surname: "",
    note: "",
    pic: "",
    variants: {},
    i: 0,
    cover: "",
    bgc: "",
    ftc: "",
    show1: false,
    show2: true,
    bg_id: 10,
    ft_id: 11,
    colorFT: "#b08f26",
    colorBG: "#cdb96e",
    clFT: "",
    variant_all: "",
    clBG: "",
    name: "",
    code: "",
    codeRing: [],
    img: "",
    size: "",
    font: "",
    ring: "",
    sizeRing: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
    sizeRing1: [
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
      60,
      61,
      62,
      63,
      64,
      65,
      66,
      67,
      68,
      69,
      70,
      71,
      72,
    ],
    fonts: [
      // {
      //   name: "[T01] TS-kaewphet Extra NP",
      //   id: "T01",
      //   font: "ts-kaewpet_extra-npitalic",
      // },
      { name: "[T01] Kt-smarn", id: "T01", font: "kt_smarnregular" },
      { name: "[T02] DSN MontaNa", id: "T02", font: "js_wansikaitalic" },
      {
        name: "[T03] Book_Akhanake",
        id: "T03",
        font: "book_akhanakeregular",
      },
      { name: "[T04] DSN FreeJack", id: "T04", font: "js_jetarinitalic" },
      { name: "[T05] DSN WatTana", id: "T05", font: "js_amphanitalic" },
      { name: "[T06] waspierreregular", id: "T06", font: "waspierreregular" },
      {
        name: "[T07] TS-som Tum-A-NP",
        id: "T07",
        font: "ts-som_tum_-a-npregular",
      },
      // { name: "[T10] DSN Cologne", id: "T10", font: "dsn_cologneregular" },
      {
        name: "[E01] Kubstler Script",
        id: "E01",
        font: "kunstler_scriptregular",
      },
      {
        name: "[E02] Branndoll Ny Personal Use Only",
        id: "E02",
        font: "brannboll_ny_personal_use_oRg",
      },
      { name: "[E03] Ginchiest", id: "E03", font: "ginchiestregular" },
      {
        name: "[E04] Champion Shipmate",
        id: "E04",
        font: "champion_shipmateitalic",
      },
      // {
      //   name: "[E05] Brush Script Std",
      //   id: "E05",
      //   font: "Brush Script Std",
      // },
      // { name: "[E06] Chopinscript", id: "E06", font: "chopinscriptmedium" },
      // { name: "[E07] Vivaldi", id: "E07", font: "vivaldiregular" },
      {
        name: "[E05] Motion Picture Personal Use",
        id: "E05",
        font: "motion_picture_personal_useRg",
      },
      { name: "[E06] JS Pranee", id: "E06", font: "js_praneeitalic" },
      {
        name: "[E07] Book_Akhanake",
        id: "E07",
        font: "book_akhanakeregular",
      },
    ],
    rules: {
      required: (value) => !!value,
    },
    nameRules: [
      (v) => !!v,
      (v) => v.length <= 20 || "Name must be less than 20 characters",
    ],
    nameRules10: [
      (v) => !!v,
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    nameRules8: [
      (v) => !!v,
      (v) => v.length <= 8 || "Name must be less than 8 characters",
    ],
    fontThai: [
      // {
      //   name: "[T01] TS-kaewphet Extra NP",
      //   id: "T01",
      //   font: "ts-kaewpet_extra-npitalic",
      // },
      { name: "[T01] Kt-smarn", id: "T01", font: "kt_smarnregular" },
      { name: "[T02] DSN MontaNa", id: "T02", font: "js_wansikaitalic" },
      {
        name: "[T03] Book_Akhanake",
        id: "T03",
        font: "book_akhanakeregular",
      },
      { name: "[T04] DSN FreeJack", id: "T04", font: "js_jetarinitalic" },
      { name: "[T05] DSN WatTana", id: "T05", font: "js_amphanitalic" },
      { name: "[T06] waspierreregular", id: "T06", font: "waspierreregular" },
      {
        name: "[T07] TS-som Tum-A-NP",
        id: "T07",
        font: "ts-som_tum_-a-npregular",
      },
      // { name: "[T10] DSN Cologne", id: "T10", font: "dsn_cologneregular" },
    ],
    fontsAll: [
      // {
      //   name: "[T01] TS-kaewphet Extra NP",
      //   id: "T01",
      //   font: "ts-kaewpet_extra-npitalic",
      // },
      { name: "[T01] Kt-smarn", id: "T01", font: "kt_smarnregular" },
      { name: "[T02] DSN MontaNa", id: "T02", font: "js_wansikaitalic" },
      {
        name: "[T03] Book_Akhanake",
        id: "T03",
        font: "book_akhanakeregular",
      },
      { name: "[T04] DSN FreeJack", id: "T04", font: "js_jetarinitalic" },
      { name: "[T05] DSN WatTana", id: "T05", font: "js_amphanitalic" },
      { name: "[T06] waspierreregular", id: "T06", font: "waspierreregular" },
      {
        name: "[T07] TS-som Tum-A-NP",
        id: "T07",
        font: "ts-som_tum_-a-npregular",
      },
      // { name: "[T10] DSN Cologne", id: "T10", font: "dsn_cologneregular" },
      {
        name: "[E01] Kubstler Script",
        id: "E01",
        font: "kunstler_scriptregular",
      },
      {
        name: "[E02] Branndoll Ny Personal Use Only",
        id: "E02",
        font: "brannboll_ny_personal_use_oRg",
      },
      { name: "[E03] Ginchiest", id: "E03", font: "ginchiestregular" },
      {
        name: "[E04] Champion Shipmate",
        id: "E04",
        font: "champion_shipmateitalic",
      },
      // {
      //   name: "[E05] Brush Script Std",
      //   id: "E05",
      //   font: "Brush Script Std",
      // },
      // { name: "[E06] Chopinscript", id: "E06", font: "chopinscriptmedium" },
      // { name: "[E07] Vivaldi", id: "E07", font: "vivaldiregular" },
      {
        name: "[E05] Motion Picture Personal Use",
        id: "E05",
        font: "motion_picture_personal_useRg",
      },
      { name: "[E06] JS Pranee", id: "E06", font: "js_praneeitalic" },
      {
        name: "[E07] Book_Akhanake",
        id: "E07",
        font: "book_akhanakeregular",
      },
    ],
    fonteng: [
      {
        name: "[E01] Kubstler Script",
        id: "E01",
        font: "kunstler_scriptregular",
      },
      {
        name: "[E02] Branndoll Ny Personal Use Only",
        id: "E02",
        font: "brannboll_ny_personal_use_oRg",
      },
      { name: "[E03] Ginchiest", id: "E03", font: "ginchiestregular" },
      {
        name: "[E04] Champion Shipmate",
        id: "E04",
        font: "champion_shipmateitalic",
      },
      // {
      //   name: "[E05] Brush Script Std",
      //   id: "E05",
      //   font: "Brush Script Std",
      // },
      // { name: "[E06] Chopinscript", id: "E06", font: "chopinscriptmedium" },
      // { name: "[E07] Vivaldi", id: "E07", font: "vivaldiregular" },
      {
        name: "[E05] Motion Picture Personal Use",
        id: "E05",
        font: "motion_picture_personal_useRg",
      },
      { name: "[E06] JS Pranee", id: "E06", font: "js_praneeitalic" },
      {
        name: "[E07] Book_Akhanake",
        id: "E07",
        font: "book_akhanakeregular",
      },
    ],
  }),
  computed: {
    ...mapState({
      userProfile: (state) => state.sgjorder.userProfile,
    }),
  },
  methods: {
    async getProduct() {
      let category_mode = "family_ring";
      await this.$store.dispatch("getProduct", category_mode);
    },
    showProduct(value) {
      let i, j;
      this.ring = value;
      this.product = [];
      this.codeRing = [];
      this.show2 = false;
      this.show1 = true;
      for (i = 0; i < this.products.length; i++) {
        for (j = 0; j < this.products[i]["variant_goldweight"].length; j++) {
          if (this.products[i]["variant_goldweight"][j]["value"] == value) {
            this.product.push(this.products[i]);
            this.variants = this.products[i]["variant_goldweight"][j]["id"];
            this.codeRing.push(this.products[i]);
          }
        }
      }
      this.sortCode();
    },
    sortCode() {
      this.codeRing.sort((a, b) =>
        a.code > b.code ? 1 : b.code > a.code ? -1 : 0
      );
    },
    selectByCode() {
      this.variant_FTC = [];
      this.variant_BGC = [];
      this.detail = this.codeR.display_name;
      this.code = this.codeR.code;
      this.name = this.codeR.display_name;
      this.img = this.codeR.cover_image;
      this.product_id = this.codeR.id;
      this.variant_FTC.push(this.codeR.variant_font_color);
      this.variant_BGC.push(this.codeR.variant_background_color);
    },
    showDetail(event) {
      this.variant_FTC = [];
      this.variant_BGC = [];
      this.detail = event.display_name;
      this.code = event.code;
      this.codeR = event.code;
      this.name = event.display_name;
      this.img = event.cover_image;
      this.product_id = event.id;
      this.variant_FTC.push(event.variant_font_color);
      this.variant_BGC.push(event.variant_background_color);
    },
    getText(item) {
      return `${item.id} - `;
    },
    clear() {
      this.surname = "";
      this.note = "";
      this.size = "";
      this.font = "";
      this.bgc = "";
      this.ftc = "";
      this.colorFT = "#b08f26";
      this.colorBG = "#cdb96e";
    },
    reset() {
      this.colorFT = "#b08f26";
      this.colorBG = "#cdb96e";
      this.bgc = "";
      this.ftc = "";
      this.clFT = "";
      this.clBG = "";
      this.bg_id = 10;
      this.ft_id = 11;
      this.variant_all = "";
      this.surname = ""
    },
    saveOrder() {
      if (this.surname == "" || this.size == "" || this.font == "") {
        this.$store.commit("SET_DIALOGW", true);
      } else if (this.variant_BGC[0] == 0 && this.variant_FTC[0] == 0) {
        this.variant_all = [this.variants, this.bg_id, this.ft_id];
        this.$store.commit("SET_DETAIL", {
          product_id: this.product_id,
          product_type: "ring",
          code: this.code,
          name: this.name,
          text: this.surname,
          note: this.note,
          img: this.img,
          size: this.size,
          colorFT: this.clFT,
          colorBG: this.clBG,
          font: this.font,
          variant_weight: this.variants,
          variant_font: this.ft_id,
          variant_background: this.bg_id,
          variants: this.variant_all,
        });
        this.$store.commit("SET_DIALOG", true);
      }

      if (this.variant_BGC[0] != 0) {
        if (
          this.clBG == "" ||
          this.surname == "" ||
          this.size == "" ||
          this.font == ""
        ) {
          this.$store.commit("SET_DIALOGW", true);
        } else {
          this.variant_all = [this.variants, this.bg_id, this.ft_id];
          this.$store.commit("SET_DETAIL", {
            product_id: this.product_id,
            product_type: "ring",
            code: this.code,
            name: this.name,
            text: this.surname,
            note: this.note,
            img: this.img,
            size: this.size,
            colorFT: this.clFT,
            colorBG: this.clBG,
            variant_weight: this.variants,
            variant_font: this.ft_id,
            variant_background: this.bg_id,
            font: this.font,
            variants: this.variant_all,
          });
          this.$store.commit("SET_DIALOG", true);
        }
      }
      if (this.variant_FTC[0] != 0) {
        if (
          this.clFT == "" ||
          this.surname == "" ||
          this.size == "" ||
          this.font == ""
        ) {
          this.$store.commit("SET_DIALOGW", true);
        } else {
          this.variant_all = [this.variants, this.bg_id, this.ft_id];
          this.$store.commit("SET_DETAIL", {
            product_id: this.product_id,
            product_type: "ring",
            code: this.code,
            name: this.name,
            text: this.surname,
            note: this.note,
            img: this.img,
            size: this.size,
            colorFT: this.clFT,
            colorBG: this.clBG,
            variant_weight: this.variants,
            variant_font: this.ft_id,
            variant_background: this.bg_id,
            font: this.font,
            variants: this.variant_all,
          });
          this.$store.commit("SET_DIALOG", true);
        }
      }
    },
    eventFont(event) {
      this.font = event;
    },
    eventBGC() {
      this.colorBG = this.bgc.value;
      this.bg_id = this.bgc.id;
      this.clBG = this.bgc.name;
    },
    eventFTC() {
      this.colorFT = this.ftc.value;
      this.ft_id = this.ftc.id;
      this.clFT = this.ftc.name;
    },
    checkChar(event) {
      let max = 0;
      for (let i = 0; i < event.length; i++) {
        if (event.charCodeAt(i) > max) {
          max = event.charCodeAt(i);
          console.log(max);
        }
      }
      if (max <= 122) {
        this.fonts = this.fonteng;
      } else if (max <= 3660) {
        this.fonts = this.fontThai;
      } else {
        this.fonts = this.fontsAll;
      }
    },
    changeFont() {
      if (this.font == "E01") {
        return "kunstler_scriptregular";
      } else if (this.font == "E02") {
        return "brannboll_ny_personal_use_oRg";
      } else if (this.font == "E03") {
        return "ginchiestregular";
      } else if (this.font == "E04") {
        return "champion_shipmateitalic";
      } else if (this.font == "E05") {
        return "motion_picture_personal_useRg";
      } else if (this.font == "E06") {
        return "js_praneeitalic";
      } else if (this.font == "E07") {
        return "book_akhanakeregular";
        // } else if (this.font == "E08") {
        //   return "motion_picture_personal_useRg";
        // } else if (this.font == "E09") {
        //   return "js_praneeitalic";
        // } else if (this.font == "E10") {
        //   return "book_akhanakeregular";
        // } else if (this.font == "T01") {
        //   return "ts-kaewpet_extra-npitalic";
      } else if (this.font == "T01") {
        return "kt_smarnregular";
      } else if (this.font == "T02") {
        return "js_wansikaitalic";
      } else if (this.font == "T03") {
        return "book_akhanakeregular";
      } else if (this.font == "T04") {
        return "js_jetarinitalic";
      } else if (this.font == "T05") {
        return "js_amphanitalic";
        // name: "[T06] DSN FreeJack", id: "T06", font: "js_jetarinitalic"
      } else if (this.font == "T06") {
        return "waspierreregular";
      } else if (this.font == "T07") {
        return "ts-som_tum_-a-npregular";
        // } else if (this.font == "T10") {
        //   return "dsn_cologneregular";
      } else {
        return "Kanit", "sans-serif";
      }
    },
    async getUserProfile() {
      // const profile = await liff.getProfile();
      // const user = {
      //   line_uuid: profile.userId,
      //   display_name: profile.displayName,
      //   picture_url: profile.pictureUrl,
      // };
      // this.$store.commit("SET_USER", user);
      // this.$store.commit("SET_SHOWBTN", true);
      // this.$store.dispatch("createLineUser", user);
      // let userState = await this.$store.dispatch("getUser", user.line_uuid);
      // console.log('stateUser', userState)
      // if (userState.state != "done") {
      //   this.$router.push("/order");
      // }
    },
  },
  async mounted() {
    this.products = await this.$store.dispatch("getProduct", "family_ring");
    // await liff.init({ liffId: process.env.VUE_APP_MY_ENV_LINELIFF });
    // liff.ready.then(() => {
    //   if (!liff.isLoggedIn()) {
    //     liff.login();
    //   }
    //   this.getUserProfile();
    // });

  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100&display=swap");

* {
  font-family: "Kanit", sans-serif;
  letter-spacing: 1px;
}

@font-face {
  font-family: "ginchiestregular";
  src: url("../assets/fontsss/ginchiest-webfont.woff2") format("woff2"),
    url("../assets/fontsss/ginchiest-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brush Script Std";
  src: url("../assets/fontsss/kunstler-webfont.woff2") format("woff2"),
    url("../assets/fontsss/kunstler-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ts-phikun_2.2-npbold_italic";
  src: url("../assets/fontsss/ts-phikun_2.2a_-np-webfont.woff2") format("woff2"),
    url("../assets/fontsss/ts-phikun_2.2a_-np-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "waspierreregular";
  src: url("../assets/fontsss/waspierre-bold-webfont.woff2") format("woff2"),
    url("../assets/fontsss/waspierre-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "kunstler_scriptregular";
  src: url("../assets/fonts/BrushScriptStd.woff2") format("woff2"),
    url("../assets/fonts/BrushScriptStd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "kt_smarnregular";
  src: url("../assets/fonts/ktsmarn.woff2") format("woff2"),
    url("../assets/fonts/ktsmarn.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "motion_picture_personal_useRg";
  src: url("../assets/fontsss/motionpicture_personaluseonly-webfont.woff2") format("woff2"),
    url("../assets/fontsss/motionpicture_personaluseonly-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "book_akhanakeregular";
  src: url("../assets/fonts/BookAkhanake.woff2") format("woff2"),
    url("../assets/fonts/BookAkhanake.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "js_praneeitalic";
  src: url("../assets/fontsss/jspranee-italic-webfont.woff2") format("woff2"),
    url("../assets/fontsss/jspranee-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "chopinscriptmedium";
  src: url("../assets/fontsss/chopinscript-webfont.woff2") format("woff2"),
    url("../assets/fontsss/chopinscript-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "brannboll_ny_personal_use_oRg";
  src: url("../assets/fontsss/brannbollnypersonaluseonly-webfont.woff2") format("woff2"),
    url("../assets/fontsss/brannbollnypersonaluseonly-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "champion_shipmateitalic";
  src: url("../assets/fontsss/championshipmate-italic-webfont.woff2") format("woff2"),
    url("../assets/fontsss/championshipmate-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ts-kaewpet_extra-npitalic";
  src: url("../assets/fonts/TS-kaewPetExtra-NP-Italic.woff2") format("woff2"),
    url("../assets/fonts/TS-kaewPetExtra-NP-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ts-som_tum_-a-npregular";
  src: url("../assets/fonts/TS-Somtum-A-NP.woff2") format("woff2"),
    url("../assets/fonts/TS-Somtum-A-NP.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "vivaldiregular";
  src: url("../assets/fontsss/vivaldi-webfont.woff2") format("woff2"),
    url("../assets/fontsss/vivaldi-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "wastawarawadeetestregular";
  src: url("../assets/fontsss/wastawarawadeetest-webfont.woff2") format("woff2"),
    url("../assets/fontsss/wastawarawadeetest-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dsn_cologneregular";
  src: url("../assets/fonts/DSNCologne.woff2") format("woff2"),
    url("../assets/fonts/DSNCologne.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "js_wansikaitalic";
  src: url("../assets/fonts/JSWansika-Italic.woff2") format("woff2"),
    url("../assets/fonts/JSWansika-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "js_amphanitalic";
  src: url("../assets/fonts/JSAmphan-Italic.woff2") format("woff2"),
    url("../assets/fonts/JSAmphan-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "js_jetarinitalic";
  src: url("../assets/fonts/JSJetarin-Italic.woff2") format("woff2"),
    url("../assets/fonts/JSJetarin-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.button {
  width: 100px;
  height: 50px;
  font-weight: bold;
}

.textF {
  width: 300px;
  height: 50px;
}

.box {
  width: 200px;
  height: 200px;
}

.size {
  width: 300px;
  height: 50px;
}
</style>
