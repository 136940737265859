<template>
  <div>
    <v-col align="center">
      <v-card> 
        กรุณาแสกนคิวอาร์โค้ดเพื่อใช้งาน
      </v-card>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "NotAuthorize",
};
</script>