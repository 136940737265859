<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Order Detail</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-img :src="`data:image/jpg; base64,` + orderDetail.img"></v-img>
            <v-img v-if="orderDetail.product_type == 'neck'" :src="`data:image/jpg; base64,` + orderDetail.imgN"></v-img>
            <div v-if="orderDetail.product_type == 'ring'">
              <h3>รหัสรูปแบบ : {{ orderDetail.code }}</h3>
              <h3>รูปแบบ : {{ orderDetail.name }}</h3>
              <h3>ขนาด : {{ orderDetail.size }} นิ้ว</h3>
              <h3>ข้อความบนแหวน : {{ orderDetail.text }}</h3>
              <h3>ฟอนต์ : {{ orderDetail.font }}</h3>
              <h3>
                สีตัวอักษร :
                {{ orderDetail.colorFT == "" ? "-" : orderDetail.colorFT }}
              </h3>
              <h3>
                สีพื้นหลัง :
                {{ orderDetail.colorBG == "" ? "-" : orderDetail.colorBG }}
              </h3>
              <h3>
                หมายเหตุ : {{ orderDetail.note == "" ? "-" : orderDetail.note }}
              </h3>
            </div>
            <div v-else-if="orderDetail.product_type == 'neck'">
              <h3>รหัสรูปแบบ : {{ orderDetail.code }}</h3>
              <h3>สร้อยคอ : {{ orderDetail.name }}</h3>
              <h3>ขนาด : {{ orderDetail.size }} นิ้ว</h3>
              <h3>ข้อความบนจี้: {{ orderDetail.text }}</h3>
              <h3>ฟอนต์ : {{ orderDetail.font }}</h3>
              <h3>
                หมายเหตุ : {{ orderDetail.note == "" ? "-" : orderDetail.note }}
              </h3>
            </div>
            <div v-else>
              <h3>รหัสรูปแบบ : {{ orderDetail.code }}</h3>
              <h3>ตำแหน่งห่วง: {{ orderDetail.variant_position_pendant == 54 ? 'กลาง': orderDetail.variant_position_pendant == 55 ? 'หน้า - หลัง' : 'หน้า' }}</h3>
              <h3>ข้อความบนจี้: {{ orderDetail.text }}</h3>
              <h3>ฟอนต์ : {{ orderDetail.font }}</h3>
              <h3>
                หมายเหตุ : {{ orderDetail.note == "" ? "-" : orderDetail.note }}
              </h3>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="button" @click="close" color="pink darken-4"
            ><h4 style="color:#cdb96e;">CLOSE</h4></v-btn
          >
          <v-btn class="button" @click="confirmOrder" color="pink darken-4"
            ><h4 style="color:#cdb96e;">CONFIRM</h4></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Dialog",
  computed: {
    ...mapState({
      dialog: (state) => state.sgjorder.dialog,
      orderDetail: (state) => state.sgjorder.orderDetail,
      user: (state) => state.sgjorder.user,
      userProfile: (state) => state.sgjorder.userProfile,
      branch: (state) => state.sgjorder.branch,
    }),
  },
  methods: {
    close() {
      this.$store.commit("SET_DETAIL", {
        product_id: "",
        code: "",
        name: "",
        text: "",
        note: "",
        img: "",
        size: "",
        colorFT: "",
        colorBG: "",
        font: "",
        variants: "",
      });
      this.$store.commit("SET_DIALOG", false);
    },
    async confirmOrder() {
      this.$store.commit("SET_DIALOG", false);
      let company = JSON.parse(sessionStorage.getItem('company_id'));
      let branch = JSON.parse(sessionStorage.getItem('branch_no'));
      let token = JSON.parse(sessionStorage.getItem('token'));
      let detail = {
        line_partner_id: this.userProfile.id,
        product_id: this.orderDetail.product_id,
        product_type:this.orderDetail.product_type,
        text: this.orderDetail.text,
        note: this.orderDetail.note == "" ? "-" : this.orderDetail.note,
        size: this.orderDetail.size,
        web: true,
        variant_weight: this.orderDetail.variant_weight,
        variant_font: this.orderDetail.variant_font,
        variant_background: this.orderDetail.variant_background,
        variant_pendant : this.orderDetail.variant_pendant,
        variant_position_pendant:this.orderDetail.variant_position_pendant,
        product_variants: this.orderDetail.variants,
        font: this.orderDetail.font,
        company_id: company,
        branch_no: branch,
        token: token,
      };
      console.log(detail);
      await this.$store.dispatch("createOrder", detail);
      this.$router.push("/history");
    },
  },
};
</script>

<style lang="sass" scoped>
.button
    width:100px
    height: 50px
</style>
