<template>
  <v-app>
    <v-app-bar app color="pink darken-4" dense dark v-show="showbtn">
      <img :src="image1" class="image" @click="$router.push('/home')" height="48" width="48" />
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="$router.push('/order')"
        color="#cdb96e"
      >
        <v-icon>mdi-cart-plus</v-icon>
      </v-btn>
      <!-- <router-link to="order">/order</router-link>
      <router-link to="order" tag="button"><v-icon>mdi-cart-plus</v-icon></router-link> -->
      <v-btn
        v-show="showbtn"
        icon
        @click="$router.push('/history')"
        color="#cdb96e"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
      <v-btn icon @click="$router.push('/category')" color="#cdb96e">
        <v-icon>mdi-book-open-blank-variant</v-icon>
      </v-btn>
      <v-btn icon @click="$router.push('/profile')" color="#cdb96e">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import 'dotenv/config'
// import liff from "@line/liff";
export default {
  name: "App",

  data() {
    return {
      image1: require("./assets/yktlogo.png"),
    };
  },
  computed: {
    ...mapState({
      showbtn: (state) => state.sgjorder.showbtn,
      // userProfile: (state) => state.sgjorder.userProfile,
    }),
  },
  async mounted() {
    let company = sessionStorage.getItem('company_id');
    // console.log(company.length);
    if (company != undefined) {
      let qcom = this.$route.query.company;
      let qbranch = this.$route.query.branch;
      let qtoken = this.$route.query.token;
      // console.log(qcom);
      if (qcom != undefined && qbranch != undefined && qtoken != undefined) {
        sessionStorage.setItem("company_id", JSON.stringify(this.$route.query.company));
        sessionStorage.setItem("branch_no", JSON.stringify(this.$route.query.branch));
        sessionStorage.setItem("token", JSON.stringify(this.$route.query.token));
        this.$store.commit("SET_SHOWBTN", true);
        this.$store.commit("SET_COMPANY", this.$route.query.company);
        this.$store.commit("SET_BRANCH", this.$route.query.branch);
        this.$store.commit("SET_TOKEN", this.$route.query.token);
      }
      else{
        this.$store.commit("SET_SHOWBTN", false);
        this.$router.push('/NotAuthorize');
      }
      // if (isLocalStorage() /* function to detect if localstorage is supported*/) {
      // localStorage.setItem('company', this.$route.query.company)
      // }


      // console.log(this.$route.query.company);
    }
    else{
      let qcom = this.$route.query.company;
      let qbranch = this.$route.query.branch;
      let qtoken = this.$route.query.token;
      if (qcom != undefined && qbranch != undefined && qtoken != undefined) {
        sessionStorage.setItem("company_id", JSON.stringify(this.$route.query.company));
        sessionStorage.setItem("branch_no", JSON.stringify(this.$route.query.branch));
        sessionStorage.setItem("token", JSON.stringify(this.$route.query.token));
        this.$store.commit("SET_SHOWBTN", true);
        this.$store.commit("SET_COMPANY", this.$route.query.company);
        this.$store.commit("SET_BRANCH", this.$route.query.branch);
        this.$store.commit("SET_TOKEN", this.$route.query.token);
      }
      else{
        this.$store.commit("SET_SHOWBTN", false);
        this.$router.push('/NotAuthorize');
      }
    }
    // this.getUserProfile();
    // await liff.init({ liffId: process.env.VUE_APP_MY_ENV_LINELIFF });
    // liff.ready.then(() => {
    //   if (liff.isLoggedIn()) {
    //     this.getUserProfile();
    //   }
    // });
  },
  methods: {
    async getUserProfile() {
      this.$store.commit("SET_SHOWBTN", true);
      // const profile = await liff.getProfile();
      // const user = {
      //   line_uuid: profile.userId,
      //   display_name: profile.displayName,
      //   picture_url: profile.pictureUrl,
      // };
      // this.$store.commit("SET_USER", user);
      // this.$store.commit("SET_SHOWBTN", true);
      // this.$store.dispatch("createLineUser", user);
      // await this.$store.dispatch("getUser", user.line_uuid);
      // console.log(user);
      // let data = {
      //   id: this.userProfile.id,
      //   picture_url: this.userProfile.picture_url,
      //   line_uuid: this.userProfile.line_uuid,
      // };
      // await this.$store.dispatch("addPic", data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100&display=swap");
* {
  font-family: "Kanit", sans-serif;
  letter-spacing: 1px;
}

.text {
  color: #cdb96e;
  font-weight: bold;
}
.image {
  // width: 180px;
  margin-top: 5px;
}
</style>
