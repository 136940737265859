<template>
  <div id="home">
    <v-col align="center">
      <v-card>
        <div>
          <img v-bind:src="pic" class="image-logo" />
        </div>
        <div class="color-wrap" align="center">
          <v-col>
            <p class="font-title">{{ title }}</p>
            <img v-bind:src="image" class="image-wrap" />
            <div class="pb-5">
              <v-btn class="button" @click="$router.push('/order')">
                See More
                <v-icon right dark> mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-col>
        </div>
      </v-card>
    </v-col>
    <DialogCheckName />
  </div>
</template>

<script>
import { mapState } from "vuex";
// import liff from "@line/liff";
import DialogCheckName from "../components/dialogCheckName.vue";
export default {
  name: "Home",
  components: { DialogCheckName },
  data() {
    return {
      title: "แหวนนามสกุล",
      image: require("../assets/ring_shadow.png"),
      pic: require("../assets/yktlogo.png"),
      btn: "button",
    };
  },
  methods: {
    async checkName() {
      if (this.userProfile.name == false) {
        this.$store.commit("SET_DIALOGW", true);
      } else {
        console.log(true);
      }
    },
     async getUserProfile() {
      // const profile = await liff.getProfile();
      // const user = {
      //   line_uuid: profile.userId,
      //   display_name: profile.displayName,
      //   picture_url: profile.pictureUrl,
      // };
      // this.$store.commit("SET_USER", user);
      // this.$store.commit("SET_SHOWBTN", true);
      // this.$store.dispatch("createLineUser", user);
      // await this.$store.dispatch("getUser", user.line_uuid);
    },
    
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.sgjorder.userProfile,
    }),
  },
  async mounted() {
    //   await liff.init({ liffId: process.env.VUE_APP_MY_ENV_LINELIFF  });
    // liff.ready.then(() => {
    //   if (!liff.isLoggedIn()) {
    //     liff.login();
    //   }
    //   this.getUserProfile();
    // });
    // this.checkName();
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.image-wrap {
  width: 300px;
  height: 240px;
  display: block;
  object-fit: cover;
  object-position: 10% 10%;
}
.color-wrap {
  background-color: #550d2a;
  border-radius: 25px;
  position: relative;
  transform-origin: center;
  width: 300px;
  height: 420px;
  margin: 0;
  box-shadow: 5px 5px 16px rgba(0, 0, 0, 0.25);
}
.font-title {
  color: #cdb96e;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}
.image-logo {
  width: 300px;
  height: 300px;
}
.button {
  background-color: white;
  font-weight: bold;
}
.button:hover {
  background-color: black;
}
</style>
