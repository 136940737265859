<template>
  <div>
    <!-- <v-card flat class="py-12">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-btn icon @click="reloadPage">
            <v-icon>mdi:refresh</v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card> -->
    <v-card flat class="py-12">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-btn-toggle v-model="toggle_exclusive">
            <v-btn @click="showOrder((value = 'open'))">
              ยืนยัน
            </v-btn>
            <v-btn @click="showOrder((value = 'done'))">
              เสร็จสิ้น
            </v-btn>
            <v-btn @click="showOrder((value = 'done'))">
              รับสินค้า
            </v-btn>
            <v-btn @click="showOrder((value = 'draft'))">
              รอยืนยัน
            </v-btn>
            <v-btn @click="showOrder((value = ''))">
              ทั้งหมด
            </v-btn>
            <v-btn icon @click="reloadPage">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table :headers="headers" :items="product" :items-per-page="5" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="item.state == 'draft'" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "History",
  data() {
    return {
      products: [],
      product: [],
      editedItem: {},
      isShow: false,
      toggle_exclusive: false,
      dialogDelete: false,
      headers: [
        {
          text: "Number",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Date", value: "date_order" },
        { text: "Product", value: "product_name" },
        { text: "Variants", value: "product_variants" },
        { text: "Size", value: "size" },
        { text: "Text", value: "text" },
        { text: "Status", value: "state" },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.sgjorder.user,
      order: (state) => state.sgjorder.order,
      // branch: (state) => state.sgjorder.branch,
    }),
  },
  methods: {
    showOrder(value) {
      let i;
      this.product = [];

      if (value == "") {
        for (i = 0; i < this.products.length; i++) {
          this.product.push(this.products[i]);
        }
      } else {
        for (i = 0; i < this.products.length; i++) {
          if (this.products[i]["state"] == value) {
            this.product.push(this.products[i]);
          }
        }
      }
      console.log(this.product);
    },
    deleteItem(item) {
      this.editedItem = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      let company = JSON.parse(sessionStorage.getItem('company_id'));
      let branch = JSON.parse(sessionStorage.getItem('branch_no'));
      let token = JSON.parse(sessionStorage.getItem('token'));
      let detail = {
        rowid: this.editedItem.id,
        company: company,
        branch: branch,
        token: token
      };
      await this.$store.dispatch("cancelOrder", detail);
      // this.products = await this.$store.dispatch("getOrder", "A001");

      var index = this.product.indexOf(this.editedItem);
      this.editedItem.state = "cancel";
      this.product.splice(index, 1)
      this.editedItem = {};
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    },
    reloadPage() {
      // window.location.reload();

      if (location.href.indexOf("?") === -1) {
        let company = JSON.parse(sessionStorage.getItem('company_id'));
        let branch = JSON.parse(sessionStorage.getItem('branch_no'));
        let token = JSON.parse(sessionStorage.getItem('token'));
        window.location = location.href + "?company=" + company + "&branch=" + branch + "&token=" + token;
      }else{
        window.location = location.href;
      }
    }
  },
  async mounted() {
    let company = JSON.parse(sessionStorage.getItem('company_id'));
    let branch = JSON.parse(sessionStorage.getItem('branch_no'));
    let token = JSON.parse(sessionStorage.getItem('token'));
    let payload = {
      "company_id": company,
      "branch_no": branch,
      "token": token,
    }
    this.products = await this.$store.dispatch("getOrder", payload);
    this.product = this.products;
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100&display=swap');

* {
  font-family: 'Kanit', sans-serif;
  letter-spacing: 1px;
}

.box {
  width: 150px;
  height: 60px;
}
</style>
